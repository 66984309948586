import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonGrid,IonRow,IonCol
    ,IonItem,IonButton,IonInput,IonLabel,IonIcon,IonImg } from '@ionic/react';
import {chevronForwardCircleOutline} from 'ionicons/icons';
import  React from 'react';
import {useState,useEffect,useRef}from 'react';
import { toast } from '../toast';
import {useHistory}from 'react-router-dom'
import Logo from '../images/icon192x192.png';

 
const Register: React.FC = () => {
    
    const [firstName,setFirstname] = useState('');
    const [lastName,setLastname] =   useState('');
    const [email,setEmail] =         useState('');
    const [password,setPassword] =   useState('');
    const [cpassword,setCpassword] =  useState('');
    const history=useHistory();
    const fnameinput=useRef();

    useEffect( ()=> {                           //if already logged in redirect to home page
        if(localStorage.getItem('user-info')){
            history.replace('/home');
        }
        setTimeout(()=>fnameinput.current.setFocus(),1000)
    },[]) //run on 1st load only

    async function RegisterUser(){
        let item ={firstName,lastName,email,password};

        if(password != cpassword)
        { 
            toast("Passwords must match!",2000,"danger");
            setPassword('');
            setCpassword('');
            return;
        }
        
        let result = await fetch("https://test.castagne-technologies.icu/api/adduser",{
            method:"POST",
            body:JSON.stringify(item),
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })        

        result = await result.json();
        console.log(result);
        

        if(result.user){  //If user aded successfully
            toast("User Successfully Added",3000,"success");   
            localStorage.setItem("user-info",JSON.stringify(result));
            history.push('/home');
        }else{
            let msg="";
            if (result.message){ msg=result.message.split("(",1);}           //catch SQL errors
            else{
                let res_array= Object.values(result);           // remove values from object
                res_array.forEach( (i)=> msg =msg+i+"<br/>" );  //compile errors into 1 string
            }                
            console.log(msg);
            toast(msg,5000,"danger");
        }
        
        
    }

    return (  
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="ion-text-center"><IonTitle > Register </IonTitle></IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding ion-margin '>
                <IonGrid >
                <IonRow>
                        <IonCol size="4" offset="4" offsetSm="4" offsetLg="4">
                            <IonImg src="../assets/icon/icon512x512.svg" />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol  size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                    <IonLabel position="floating"  color="primary" className="ion-justify-content-center">First Name</IonLabel>
                                    <IonInput value={firstName} ref={fnameinput}
                                        onIonChange={(e:any) => setFirstname(e.target.value)}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol  size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel  position="floating" color="primary">Last Name</IonLabel>
                                <IonInput value={lastName}  
                                     onIonChange={(e:any) => setLastname(e.target.value)}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel position="floating" color="primary">Email</IonLabel> 
                                <IonInput value={email} 
                                onIonChange={(e:any) => setEmail(e.target.value)}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol  size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel position="floating" color="primary" >Password</IonLabel>
                                <IonInput type="password" value={password}
                                onIonChange={(e:any) => setPassword(e.target.value)}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol   size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel position="floating" color="primary" >Confirm Password</IonLabel>
                                <IonInput type="password" value={cpassword}
                                onIonChange={(e:any) => setCpassword(e.target.value) } onKeyDown={(e:any) => (e.key=="Enter")&& RegisterUser() } />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow  >
                        <IonCol size='8' offset="2"  className='ion-padding'>    
                        <IonButton onClick={RegisterUser} expand="full">Register
                                    <IonIcon slot="end" icon={chevronForwardCircleOutline} />
                                </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            </IonPage>
    );
}
 
export default Register ;