import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonGrid,IonRow,IonCol
    ,IonItem,IonButton,IonInput,IonLabel,IonIcon,IonImg } from '@ionic/react';
import {chevronForwardCircleOutline} from 'ionicons/icons';
import  React from 'react';
import {useState,useEffect,useRef}from 'react';
import { toast } from '../toast';
import {useHistory,useLocation}from 'react-router-dom';
import queryString from 'query-string';

 
const UpdatePwd: React.FC = () => {
    
    const [password,setPassword] =   useState('');
    const [cpassword,setCpassword] =  useState('');
    const {search} = useLocation();
    const {token , email } = queryString.parse(search);  //extract params from url
    const history=useHistory();
    const passinput=useRef();

    useEffect( ()=> {                           //if already logged in redirect to home page
        setTimeout(()=>passinput.current.setFocus(),1000);
    },[]) //run on 1st load only

    async function UpdatePWD(){
        let password_confirmation=cpassword;
        let item ={token, email,password,password_confirmation};

        if(password != cpassword)
        { 
            toast("Passwords must match!",2000,"danger");
            setPassword('');
            setCpassword('');
            return;
        }
        let result = await fetch("https://test.castagne-technologies.icu/reset-password",{
            method:"POST",
            body:JSON.stringify(item),
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })
        console.log(result);
        let result1 = await result.json(); 
        if(result.status==200){
            toast(result1.message,3000,"success");
            history.replace("/login");
            
        }else{
            let errormsg= result1.message+"<br/>";
            errormsg=(result1.errors.password)?(errormsg+result1.errors.password[0]+"<br/>"):errormsg;
            errormsg=(result1.errors.email)?(errormsg+result1.errors.email[0]+"<br/>"):errormsg;
            toast(errormsg,3000,"danger");
            console.log(errormsg);
        }
        console.log(result1);
        
    }

    return (  
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="ion-text-center"><IonTitle > Reset Password </IonTitle></IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding ion-margin '>
                <IonGrid >
                    <IonRow>
                        <IonCol size="4" offset="4" offsetSm="4" offsetLg="4">
                            <IonImg src="../assets/icon/icon512x512.svg" />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel position="floating" color="primary">Email</IonLabel> 
                                <IonInput value={email}  disabled={true}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol  size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel position="floating" color="primary" >Password</IonLabel>
                                <IonInput type="password" value={password} ref={passinput}
                                onIonChange={(e:any) => setPassword(e.target.value)}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol   size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel position="floating" color="primary" >Confirm Password</IonLabel>
                                <IonInput type="password" value={cpassword}
                                onIonChange={(e:any) => setCpassword(e.target.value) } onKeyDown={(e:any) => (e.key=="Enter")&& UpdatePWD() } />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow  >
                        <IonCol size='8' offset="2"  className='ion-padding'>    
                            <IonButton onClick={UpdatePWD} expand="full" shape="round">Update Password
                                    <IonIcon slot="end" icon={chevronForwardCircleOutline} />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            </IonPage>
    );
}
 
export default UpdatePwd ;