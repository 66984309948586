//This page needs query parameters eg: /chart?id=4&uom="inches" 

import React from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";
import { withRouter } from "react-router";
import { IonHeader, IonButton,IonIcon, IonTitle, IonToolbar ,IonButtons, IonSpinner,IonPage,IonContent,
  IonGrid,IonRow,IonCol,IonLabel} from '@ionic/react';
import {arrowBackOutline} from 'ionicons/icons';
import { toast } from '../toast';

ReactFC.fcRoot(FusionCharts, TimeSeries);

  const jsonify = res => res.json();
  const dataSource = {
    chart: {},
    caption: {
      text: "Historical Tank Levels"
    },
    subcaption: {
      text: "Tank Name"
    },
    yaxis: [
      {
        plot: [
          {
            value: "Inches",
            type: "column"
          }
        ]
      }
    ]
  };

class ChartViewer extends React.Component {
  constructor(props) {
    super(props);

    this.onFetchData = this.onFetchData.bind(this);
    this.houseKeeping= this.houseKeeping.bind(this);
    this.state = {
      timeseriesDs: {
        id:'chart1',
        type: "timeseries",
        renderAt: "container",
        width: "90%",
        height: window.innerHeight-60,
        dataSource
      }
    };

  }

  componentDidMount() {
    this.onFetchData();
    //const timer1 = setTimeout(this.onFetchData, 15000); 
  }
  

  houseKeeping(){   // resize window if phone rotated

    try{
      FusionCharts.items['chart1'].resizeTo("90%",window.innerHeight-60);
    }catch(err){
      if ( err.message.toLowerCase().includes("fetch"))
      {
        toast("No Internet Connection",8000,"danger");
        this.setState({
        });
      }
    } 
    


  }

  onFetchData() {
    
      const id=new URLSearchParams(this.props.location.search).get("id");
      const token=new URLSearchParams(this.props.location.search).get("token");
      const  dataFetch = fetch("https://test.castagne-technologies.icu/api/getreadings",
      {   
          method:"POST",
          body:JSON.stringify({device_id:id}),
          headers:{
              "Content-Type":"application/json",
              "Accept":"application/json",
              "Authorization": "Bearer "+token
          }
      }) .then(jsonify)
      .catch((err)=>
        {
          console.log(err);
          toast("No Internet Connection",8000,"danger");
        });
      
      Promise.all([dataFetch]).then(res => {
        
        const fetch_data = res[0];
        console.log(fetch_data);
        const sensorHeight=parseInt(fetch_data.info.sensorHeight);
        this.state.timeseriesDs.dataSource.caption.text= fetch_data.info.description+" Levels";
        this.state.timeseriesDs.dataSource.subcaption.text= parseInt(fetch_data.info.volume).toLocaleString('en-US')+ " Gallon";
        const data=[];
        const td=new Date(fetch_data.data[0].created_at);
        fetch_data.data.map( (reading, index)=>{
            const d = new Date(reading.created_at);
            data.push([d.toLocaleString('en-US', { timeZone: 'America/Anguilla' }), sensorHeight-reading.value]);
        }); 
        const schema = [{
            "name": "Time",
            "type": "date",
            "format": "%m/%d/%Y, %H:%M:%S %p"
          },
          {
            "name": "Inches",
            "type": "number"
          }
        ];
        const fusionTable = new FusionCharts.DataStore().createDataTable(
        data,
        schema
        );
        const refLine=[
          {
            label:"Low Level Alarm", //Label of the reference line
            value: fetch_data.info.alarm //Value of the reference line
          }
        ];
        const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
        timeseriesDs.dataSource.data = fusionTable;
        timeseriesDs.dataSource.yaxis[0].referenceLine = refLine;
        this.setState({
          timeseriesDs,
        });
        const timer2 = setInterval(this.houseKeeping, 1000); 
      })
    .catch((err)=>{
      console.error(err);
    });
  }


  render() {

    return (
      <React.Fragment>
        <IonPage>
          <IonHeader>                                              {/*Toolbar*/}
          <IonToolbar color = "primary" className="ion-text-center">
            <IonButton  slot="start" color="light"  fill="clear" href="/home">
                    <IonIcon icon={arrowBackOutline} />
            </IonButton>
            <IonButtons slot="start" >
            </IonButtons>
            <IonTitle color="light"><h2>Tank Levels</h2></IonTitle>
          </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="ion-text-center"> 
            <IonGrid >
              <IonRow className="ion-text-center">
                <IonCol size="12" className="ion-text-center" >
                      {this.state.timeseriesDs.dataSource.data ? (
                          <ReactFC {...this.state.timeseriesDs} />
                            ) : (
                            <IonSpinner color="primary" ></IonSpinner>)}
                </IonCol>
              </IonRow> 
            </IonGrid>       
          </IonContent>
        </IonPage>
      </React.Fragment>
    );
  }
}
export default withRouter(ChartViewer);