//Fusion Charts Cylinder Element 
//Resources 
// https://www.fusioncharts.com/dev/chart-guide/gauges-and-widgets/cylinder-gauge Guide on Useage
// https://www.fusioncharts.com/dev/getting-started/react/your-first-chart-using-react  Configuration Guide React
//
import React from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Cylinder from "fusioncharts/fusioncharts.widgets";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { getTankData } from "../functions";
import { maxHeaderSize } from "http";

ReactFC.fcRoot(FusionCharts, Cylinder, FusionTheme);


const LevelChart = ({uom,value,desc,color,updated,timeout ,volume ,
                    sensorHeight, fillDepth}) => { 
  
  function timediff( dateFromAPI){ // finds difference between date and Now

    //const dateFromAPI = "2021-05-09T00:00:00Z";
    const n = new Date();   //create date with browser timezone
    const now= new Date(n.toLocaleString('en-US', { timeZone: 'UTC' }));//convert to UTC timezone
    const d =new Date(dateFromAPI);//create date from API in API timezone
    const datefromAPI =new Date(d.toLocaleString('en-US', { timeZone: 'UTC' }));//convert API date to UTC
    const datefromAPITimeStamp =datefromAPI.getTime();//convert API Date to milliseconds
    const nowTimeStamp = now.getTime();//convert browser Date  to milliseconds
    const microSecondsDiff = Math.abs(datefromAPITimeStamp - nowTimeStamp);
    
    // Math.round is used instead of Math.floor to account for certain DST cases
    // Number of milliseconds per day =
    //   24 hrs/day * 60 minutes/hour * 60 seconds/minute * 1000 ms/second
    let remainder = 0;
    const daysDiff = microSecondsDiff / (1000 * 60 * 60  * 24);
    remainder= daysDiff-Math.floor(daysDiff);
    const hoursDiff = (remainder*24);//adust for timezone
    remainder=hoursDiff-Math.floor(hoursDiff);
    const minsDiff = remainder *60;
    remainder=minsDiff-Math.floor(minsDiff);
    const secDiff = Math.round(remainder *60);
    const diff={
      days: Math.floor(daysDiff),
      hours:Math.floor(hoursDiff),
      minutes:Math.floor(minsDiff),
      seconds:Math.floor(secDiff),
      total_seconds: Math.round((microSecondsDiff) /1000)
    };
    return diff;
  }

  FusionCharts.options.SVGDefinitionURL = 'absolute';
  sensorHeight = parseInt(sensorHeight); fillDepth=parseInt(fillDepth);volume=parseInt(volume) ; value=parseInt(value);
  const tdiff = timediff(updated);
  const {days,hours,minutes,seconds, total_seconds}= tdiff;
  const warning_red="#D0342C";
  const withinBlindspot = (((sensorHeight-fillDepth) <= 11)&& value <= 11);//detemine if reading within 11in blindspot
  let Level=withinBlindspot?fillDepth : //if within blindspot level = fill depth
                             sensorHeight-value;//otherwise work out level in inches
  let tankLevel =volume * (Level/fillDepth);//tank level in gallons remaining
  const timeoutFlag = total_seconds < parseInt(timeout);
  const lowAlarmFlag=(uom=="inch"&& (Level<10)) || (uom=="ft"&& (Level<0.8));
  if (uom=="ft") Level=(sensorHeight-value)/12;
  let liquidColor="#DDDDDD";          //liquid color is grey by default
  if(total_seconds < parseInt(timeout)){// if device not timed out
    if(lowAlarmFlag){    //if tank level below 10 inches
      liquidColor="#FF3131";                                      //liquid color is red
    } else{
      liquidColor =color;                                         //otherwise color is the preferred 
    }
  };
  const chartConfigs = {
    type: "cylinder", // The chart type
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        caption: desc,
        subcaption:(timeoutFlag)?volume+" Gal" :"<br/>-offline-",
        subcaptionFontBold: "0",
        captionFontColor:(timeoutFlag)?"#000000" : "#999999",
        subCaptionFontColor:(timeoutFlag)?"#000000" : "#bbbb",
        lowerLimitDisplay: "Empty",
        upperLimitDisplay: "Full",
        lowerLimit: "0",
        upperLimit: (uom=="ft")?(fillDepth/12):(fillDepth),
        numberSuffix:" "+ (((window.innerWidth<540)&&(uom=="inch"))? "in":uom),
        decimals:( uom=="ft") ?1:0,
        cylfillcolor:liquidColor, //#5D62B5",
        cylfillhoveralpha: "85",
        showhovereffect: "1",
        plottooltext:   "remaining:<br/>"+
                         "<h2>"+parseInt(tankLevel).toLocaleString('en-US')+" Gal</h2>"+
                         "<small>(Sensor: "+ value + " inches )</small>"+ 
                        "<br/>Last Reading:<br/> days:"+days+"<br/>hours: "+hours+
                        "<br/>minutes:"+minutes+"<br/>seconds:"+seconds+" ago..." ,
        showvalue:(timeoutFlag)? "1":"0",
        theme: (window.innerWidth>540)? "ocean":"fusion",
        bgColor:(timeoutFlag)?"#ffffff" :"#f7f7f7",
        showBorder:"0"
      },
      // Chart Data
      value:Level
    }
  };

  return (
       <ReactFC {...chartConfigs} className='Levelchart' />

  );
};

export default LevelChart