import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonGrid,IonRow,IonCol,
    IonItem,IonButton,IonInput,IonLabel,IonIcon,IonImg,IonText, IonRouterLink } from '@ionic/react';
import {chevronForwardCircleOutline, addCircleOutline} from 'ionicons/icons';
import React, {useState,useEffect,useRef} from 'react'
import {useHistory } from 'react-router-dom'
import { toast } from '../toast'



 
const NotFound404: React.FC = () => { 

   const history=useHistory();

   useEffect ( ()=>{
        const timer1 = setTimeout(()=>{
                history.replace('/home');
        }, 4000);  

    },[])

    return (  

        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="ion-text-center"><IonTitle > Page Not Found </IonTitle></IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding ion-margin ion-text-center '>
                <IonGrid >
                    <IonRow>
                        <IonCol sizeXs="10" offsetXs="1" sizeSm="6"  offsetSm="3" sizeLg="5" offsetLg="4"   sizeXl="3" offsetXl="5">
                            <IonImg src="../assets/icon/icon512x512.svg" />
                        </IonCol>
                    </IonRow>
                    <IonRow >
                        <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3"  className="ion-text-center"> 
                             <IonText><h2>OOPs... Page Not Found </h2></IonText>
                             <IonText><h5>Redirecting you back to Login Page ...</h5></IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

 
export default NotFound404 ;