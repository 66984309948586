import { IonGrid,IonRow,IonCol,IonSpinner,IonText} from '@ionic/react';


const Loading= () => {

    return(
        <IonGrid>
            <IonRow >
                <IonCol className="ion-text-center ion-padding " size='12'>
                    <IonSpinner color="primary"></IonSpinner>
                </IonCol>
                <IonCol className="ion-text-center ion-padding" size='12' > 
                        <IonText color="medium">Loading...</IonText>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}
export default Loading;