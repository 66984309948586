import {IonIcon,IonModal,IonLabel,IonInput,IonItem, IonHeader,
    IonToolbar,IonRow,IonCol,IonFab,IonGrid,IonAlert,
    IonToggle,IonTitle,IonButton,IonContent, IonFabButton,} from '@ionic/react';
import {arrowBackOutline,arrowForward} from 'ionicons/icons';
import React, {useState,useEffect, useRef} from 'react';
import { toast } from '../toast';


const NotificationModal = ({userinfo, showModal4, setShowModal4,getLast} )=> {
    

    const [showWatsapp, setShowWatsapp] = useState(userinfo.user.notif_watsapp==1);
    const [showAlert1, setShowAlert1] = useState(false); 
    const updatedUserInfo = useRef({
        user_id:userinfo.user.id,
        account_state: userinfo.user.account_state,
        first_name: userinfo.user.first_name,
        last_name: userinfo.user.last_name,
        notif_push:  parseInt(userinfo.user.notif_push),
        notif_watsapp: parseInt(userinfo.user.notif_watsapp),
        phone: userinfo.user.phone,
        subscription_type: userinfo.user.subscription_type,
        watsapi:userinfo.user.watsapi
    });

    function handleWatsapp(e){
        (e.target.checked)?updatedUserInfo.current.notif_watsapp =1 : updatedUserInfo.current.notif_watsapp =0 ;
        if(e.target.checked){
            setShowAlert1(true);
            setShowWatsapp(true);
            (e.target.checked)?updatedUserInfo.current.notif_watsapp =1 : updatedUserInfo.current.notif_watsapp =0 ;
            console.log(e.target.checked);  
        }else{
            setShowWatsapp(false);
        }
    }


    function handleGetAPI (){
        const url="https://wa.me/%2B34644975414?text=I%20allow%20callmebot%20to%20send%20me%20messages"
        window.open(url);
    }

    
    function handleRecoverAPI (){
        const url="https://wa.me/%2B34644975414?text=Recover%20APIKey"
        window.open(url);
    }


    async function updateUser(){
        console.log(updatedUserInfo.current);

        let url="https://test.castagne-technologies.icu/api/updateuser";
        let response = await fetch(url,{
            method:"PUT",
            body:JSON.stringify(updatedUserInfo.current),
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json",
                "Authorization": "Bearer "+userinfo.token
            }
        })        

        if (response.status >= 200 && response.status <= 299) {
            const jsonResponse = await response.json();
            console.log(jsonResponse);
            toast(jsonResponse.message,2000,"success");
            setShowAlert1(false);    
            setShowModal4(false);                
            getLast();
        } else {// Handle errorss              
            console.log(response.status, response.statusText);
            toast("Error"+response.statusText,5000,"danger");    
        }

    }



        return(        
        <React.Fragment>     
            <IonModal isOpen={showModal4} animated={true} cssClass="modal-class4 "  swipeToClose={true}
                backdropDismiss={true} 
                onDidDismiss={ ()=>
                    {
                        setShowAlert1(false)
                        setShowModal4(false);
                        getLast();
                    }
                }
            >          
            <IonHeader>
                <IonToolbar color="primary" className="ion-text-center">
                     <IonButton slot='start' fill='clear' color='light'onClick={() =>{
                                setShowAlert1(false);
                                setShowModal4(false);
                            }
                         }>
                         <IonIcon icon={arrowBackOutline}   />
                     </IonButton>
                    <IonTitle>Enable Notifications</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className='bottomfab'>  
                    <IonFabButton color="primary" onClick={updateUser} fill='clear'  >
                        <IonIcon icon={arrowForward} />
                    </IonFabButton> 
                </IonFab>
                <IonAlert
                    isOpen={showAlert1}
                    onDidDismiss={() => setShowAlert1(false)}
                    cssClass=''
                    header={'Watsapp Notification Setup'}
                    subHeader={'STEPS'}
                    message={'1. click GET API KEY to open an Authorization message in watsapp .</br>2. Send the watsapp message </br> 3. Enter the API Key reply you receive .'}
                    buttons={['OK']}
                />
                <IonGrid className="ion-padding" fixed>
                    <IonRow >
                        <IonCol>
                                <IonItem>
                                    <IonLabel color="primary">Push Notifications</IonLabel>
                                    <IonToggle checked={(updatedUserInfo.current.notif_push==1)?true:false} onIonChange={(e) => {
                                        (e.target.checked)?updatedUserInfo.current.notif_push =1 : updatedUserInfo.current.notif_push =0 ;
                                     }} />
                                </IonItem>

                                <IonItem>
                                    <IonLabel color="primary">Watsapp Notifications</IonLabel>
                                    <IonToggle checked={(updatedUserInfo.current.notif_watsapp==1)?true:false} onIonChange={handleWatsapp} />
                                </IonItem>
                                    
                                {(showWatsapp)&&<>
                                            <IonItem>
                                                <IonLabel position="stacked" color="secondary" >
                                                    Mobile Number 
                                                </IonLabel> 
                                                <IonInput value={updatedUserInfo.current.phone} placeholder='eg: +18685555555 (must have watsapp'
                                                    onIonChange={(e) => updatedUserInfo.current.phone=e.target.value }/>                                              
                                                <IonButton slot="end" color="primary" onClick={handleGetAPI} >
                                                    GET API KEY
                                                </IonButton>
                                            </IonItem> 
                                            
                                            <IonItem>
                                                <IonLabel position="stacked" color="secondary" >
                                                API KEY 
                                            </IonLabel> 
                                            <IonButton slot="end" color="primary" onClick={handleRecoverAPI} >
                                                    RECOVER APIKEY
                                            </IonButton>
                                            <IonInput value={updatedUserInfo.current.watsapi} placeholder='CHeck watsapp after setup'
                                            onIonChange={(e) => updatedUserInfo.current.watsapi=e.target.value}/>
                                            </IonItem> 
                                        </>
                                    }
                                    <IonRow className="ion-padding">
                                        <IonCol className="ion-text-center ion-padding">
                                            <IonButton  color="primary" target="_blank" href={"https://api.callmebot.com/whatsapp.php?phone="+
                                                updatedUserInfo.current.phone+"&text=This+is+a+test+from+CallMeBot&apikey="+updatedUserInfo.current.watsapi }>
                                                Test
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>

                        </IonCol>
                    </IonRow>                                   

                </IonGrid>
            </IonContent>
        </IonModal>
    
        </React.Fragment>

        );
    }
    export default NotificationModal;