import { Redirect, Route } from 'react-router-dom';
import React from 'react'
import { IonApp,IonRouterOutlet,IonSpinner } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import Protected from './pages/Protected';
import Mainmenu from './components/Mainmenu'
import Logout from './pages/Logout';
import ResetPassword from './pages/ResetPassword';
import UpdatePwd from './pages/UpdatePwd';
import ChartViewer from './pages/ChartViewer';
import NotFound404 from './pages/NotFound404';
import User from './pages/User';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import Loading from './components/Loading';

// Step 1 - Include react

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';


/* Theme variables */
import './theme/variables.css';


const App: React.FC = () => {

  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={ <Loading/>} //If not pass, nothing appears at the time of new version check.
    >
      <IonApp>
        <IonReactRouter>
          <Mainmenu/>
          <IonRouterOutlet id="main" animated={true}>
            <Redirect from="http://iot.castagne-technologies.icu" to="https://iot.castagne-technologies.icu/login" />
            <Redirect exact from="/" to="/login" />
            <Route exact path="/home" >
              <Protected  Cmp={Home}  />          
            </Route>
            <Route exact path="/logout">
              <Protected  Cmp={Logout}  />
            </Route>
            <Route exact path="/update-password">
              <UpdatePwd />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/login">
              <Login/>
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
            <Route exact path="/chart" > {/*needs query parameters eg: /chart?id=4&uom=inches&token=sdsdf */}
              <ChartViewer />
            </Route> 
            <Route exact path="/user" > {/*needs query parameters eg: /chart?id=}*/}
              <Protected  Cmp={User}  />
            </Route> 
            <Route component={NotFound404} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </CacheBuster>
  );
};

export default App;


