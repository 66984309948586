import {IonFabButton,IonIcon,IonModal,IonLabel,IonInput,IonItem, IonHeader,
    IonContent,IonRow,IonCol,IonFab,IonGrid,IonToolbar, IonButton,IonTitle,
    IonImg,IonSelect,IonSelectOption} from '@ionic/react';
  import React, {useState} from 'react';
  import {chevronForwardOutline , arrowBackOutline} from 'ionicons/icons';
  import { toast } from '../toast';
  import { getTankData} from '../functions';

  const AddDevModalpg2 = ({newDevice, setNewDevice, showModal2, 
        setShowModal2,userinfo,newDeviceFlag,setNewDeviceFlag ,getLastReading} )=> {

      async function addDevice(){
        console.log(newDevice);
        if(newDevice.maxval ==0){toast("Please select a tank size",3000,"danger");return;}
        if(newDevice.description.length == 0){toast("Please enter a name.",3000,"danger");return;}
        if(newDevice.location.length == 0){toast("Please enter a location.",3000,"danger");return;}

        
        let url=(newDeviceFlag)?"https://test.castagne-technologies.icu/api/adddevice":
                             "https://test.castagne-technologies.icu/api/updatedevice";
        let result = await fetch(url,{
            method:(newDeviceFlag)?"POST":"PUT",
            body:JSON.stringify(newDevice),
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json",
                "Authorization": "Bearer "+userinfo.token
            }
        })        

        result = await result.json();
        console.log(result);
        if (result.message) {
            if (result.message=="Device Saved!"){
                toast(result.message,2000,"success");        
                setShowModal2(false);                
                setNewDeviceFlag(false);
                getLastReading();

            }else{
                toast(result.message,3000,"danger");    
            }
        }
      }
  
  
      return(
        <IonModal isOpen={showModal2} animated={true} onDidDismiss={ ()=> setShowModal2(false)}
        cssClass="modal-class1">            
            <IonHeader className="ion-text-center" >
                <IonToolbar color="primary">
                    <IonButton slot='start' fill='clear' color='light'onClick={() =>{
                        setShowModal2(false);
                        }}>
                        <IonIcon icon={arrowBackOutline}   />
                    </IonButton>
                    <IonTitle >Add Device</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding ">                            {/*CARD Content*/ }
                
                <IonFab vertical="bottom" horizontal="center" slot="fixed" className='bottomfab' >  
                    <IonFabButton color="primary" onClick={addDevice} >
                        <IonIcon icon={chevronForwardOutline} />
                    </IonFabButton>
                </IonFab>
                <IonGrid>
                    <IonRow >
                            <IonCol size="9" offset="2" sizeMd="6" offsetMd="3" className="ion-padding">
                                <IonImg src="../assets/icon/add210x230.svg"  /> 
                            </IonCol>      
                    </IonRow>
                      
                    <IonRow >
                        <IonCol className="ion-text-center " >
                             
                             <IonLabel position="stacked" color="primary" >
                                     Select Your Tank Size 
                            </IonLabel>
                            <IonSelect value={newDevice.model} placeholder="Select Tank Size" 
                                interface="action-sheet"
                                onIonChange={e => {
                                   const {sensorHeight, volume, fillDepth}=getTankData(e.target.value);
                                   setNewDevice( {...newDevice, sensorHeight:sensorHeight, volume:volume , fillDepth:fillDepth ,
                                                  model: e.target.value ,fillDepth});
                                }}>
                                <IonLabel>Select: Tank Size</IonLabel>
                                <IonSelectOption value="tuff4500">Tuff Tank-4500 Gal</IonSelectOption>
                                <IonSelectOption value="tuff3000">Tuff Tank-3000 Gal </IonSelectOption>    
                                <IonSelectOption value="tuff2000">Tuff Tank-2000 Gal </IonSelectOption>
                                <IonSelectOption value="tuff1000">Tuff Tank-1000 Gal</IonSelectOption>
                                <IonSelectOption value="tuff800">Tuff Tank-800 Gal </IonSelectOption>
                                <IonSelectOption value="tuff600">Tuff Tank-600 Gal </IonSelectOption>
                                <IonSelectOption value="tuff450">Tuff Tank-450 Gal </IonSelectOption>
                                <IonSelectOption value="tuff400">Tuff Tank-400 Gal </IonSelectOption>
                                <IonSelectOption value="tuff200">Tuff Tank-200 Gall</IonSelectOption>
                                <IonSelectOption value="slim200">SlimLine Tank-200 Gal</IonSelectOption>
                            </IonSelect>
                            <IonItem>
                               
                                <IonLabel position="floating" color="primary" >
                                     Location
                                </IonLabel> 
                                <IonInput value={newDevice.location} placeholder='example: "Home" ,"Backyard", "Roof" ... '
                                    onIonChange={(e) => 
                                    setNewDevice( {...newDevice, location:e.target.value })}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating" color="primary" >
                                    Name
                                </IonLabel> 
                                <IonInput value={newDevice.description} placeholder='example:  "Water Tank 1" , "South Tank" ...'
                                    onIonChange={(e) => setNewDevice( {...newDevice, description:e.target.value })}/>                           
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                         <br/><br/><br/>
                    </IonRow> 
                </IonGrid>
            </IonContent> 

    </IonModal>  
 
      );
  }
  export default AddDevModalpg2;