import { IonHeader, IonButton,IonIcon, IonTitle, IonToolbar ,IonButtons, IonMenuButton, IonAvatar,} from '@ionic/react';
import {notifications} from 'ionicons/icons';

const Header = ({setShowModal4}) => {

    return(
        <IonHeader>                                              {/*Toolbar*/}
          <IonToolbar color = "primary" className="ion-text-center">
            <IonButtons slot="start" >
                <IonMenuButton autoHide={false} ></IonMenuButton> 
            </IonButtons>
            <IonButton  slot="end" color="light"  fill="clear" onClick={()=> setShowModal4(true)}>
                    <IonIcon icon={notifications} />
            </IonButton>
            <IonTitle color="light"><h2>Tank Levels</h2></IonTitle>
          </IonToolbar>
        </IonHeader>

    );
}
export default Header;