
import * as firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyAKl0MrnTtCEd_5MRl3Wy0Ph3DStATYHP4",
  authDomain: "wireless-monitor.firebaseapp.com",
  projectId: "wireless-monitor",
  storageBucket: "wireless-monitor.appspot.com",
  messagingSenderId: "296226399210",
  appId: "1:296226399210:web:8479713721371d86d4c893"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

export default firebase;