import {IonFabButton,IonIcon,IonModal,IonGrid,IonText,IonLabel,IonInput,IonItem, IonHeader,
  IonContent,IonTitle,IonToolbar,IonRow,IonCol,IonFab,IonImg, IonButton} from '@ionic/react';
import React, {useRef, useEffect} from 'react';
import { toast } from '../toast'
import {chevronForwardOutline, arrowBackOutline} from 'ionicons/icons';



const AddDevModal: React.FC  = ({newDevice, setNewDevice, showModal, setShowModal,
                setShowModal2,userinfo,setNewDeviceFlag,API_input })=> { 


    async function checkApiKey(){
        setNewDeviceFlag(false);
        let msg= {user_id: userinfo.user.id , api_key:newDevice.api_key};

        let result = await fetch("https://test.castagne-technologies.icu/api/checkapi",{
            method:"POST",
            body:JSON.stringify(msg),
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json",
                "Authorization": "Bearer "+userinfo.token
            }
        })        
            
        result = await result.json();
        console.log(result); 
        if (result.device_id) {
            setNewDevice(JSON.parse(JSON.stringify(result)));
            setShowModal(false);
            setShowModal2(true); 
            toast("Valid API Key!",1000,"success");
            return;
        }

        if( result.message){
            switch (result.message) {
                case "new device":
                    setNewDevice({
                        "user_id":userinfo.user.id, 
                        "api_key":newDevice.api_key,
                        "type":"Level",
                        "description":"",
                        "location":"",
                        "maxval":100,
                        "minval":"0",
                        "gradient":"1",
                        "offset":"0",
                        "uom":"inch",
                        "lastreading":0
                    });
                    setNewDeviceFlag(true);
                    setShowModal(false); 
                    setShowModal2(true); 
                    toast("Valid API Key - New Device! ",1000,"success");
                    break;

                case "Device Already Registered to another user":
                    setShowModal(false);
                    setNewDevice( {...newDevice, api_key:"" });
                    toast(result.message,1000,"danger");
                    break;
                case "Device is Already Setup!":
                    setShowModal(false);
                    setNewDevice( {...newDevice, api_key:"" });
                    toast(result.message,1000,);
                    break;

                default:
                    setShowModal(false);
                    setShowModal2(false); 
                    toast(result.message,2000,"danger");
                    break;
            }
        }
            
    }


    return(
        <IonModal isOpen={showModal} animated={true} onDidDismiss={ ()=> setShowModal(false)}
        cssClass="modal-class">            
                <IonHeader className="ion-text-center" >
                    <IonToolbar color="primary">
                    <IonButton slot='start' fill='clear' color='light'onClick={() =>{
                        setShowModal(false);
                     }}>
                    <IonIcon icon={arrowBackOutline}   />
                </IonButton>
                        <IonTitle >Add Device</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent >                            
                    <IonFab vertical="bottom" horizontal="center" slot="fixed" className='bottomfab'>  
                        <IonFabButton color="primary" onClick={checkApiKey}  >
                            <IonIcon icon={chevronForwardOutline} />
                        </IonFabButton>
                    </IonFab>
                    <IonGrid fixed={true} >
                        <IonRow >
                            <IonCol size="9" offset="2" sizeMd="6" offsetMd="3" className="ion-padding">
                                <IonImg src="../assets/icon/add210x230.svg"  /> 
                            </IonCol>                     
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonText color='medium' >
                                Enter the API key printed on the device's label
                                </IonText>
                            </IonCol>                          
                        </IonRow>
                        <IonRow >
                            <IonCol size="12" sizeMd="8" offsetMd="2" className="ion-padding">
                            <IonItem>
                                <IonLabel className="ion-text-center " position="floating" color="primary" >
                                    API Key
                                </IonLabel> 
                                <IonInput value={newDevice.api_key}   ref={API_input}
                                onIonChange={(e:any) => setNewDevice( {...newDevice, api_key:e.target.value })}/>
                            </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <br/><br/><br/>
                        </IonRow> 
                    </IonGrid>
                </IonContent> 
           
        </IonModal>  

    );
}
export default AddDevModal;