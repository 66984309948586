import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonGrid,IonRow,IonCol,
    IonItem,IonButton,IonInput,IonLabel,IonIcon,IonImg,IonText, IonRouterLink } from '@ionic/react';
import {chevronForwardCircleOutline, addCircleOutline} from 'ionicons/icons';
import React, {useState,useEffect,useRef} from 'react'
import {useHistory } from 'react-router-dom'
import { toast } from '../toast'



 
const Login: React.FC = () => { 

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const history=useHistory();
    const emailinput =useRef();
    const passinput =useRef();

    if(localStorage.getItem('user-info')){
        history.push('/home');
    }

    useEffect ( ()=>{
        setTimeout(()=>emailinput.current.setFocus(),1000);
        
    },[])

    async function loginUser()
    {
        let item ={email,password};

        let result = await fetch("https://test.castagne-technologies.icu/api/login",{
            method:"POST",
            body:JSON.stringify(item),
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })       
        console.log(result);
        result = await result.json();

        
        if(result.user){    //If user aded successfully
            toast("Successfully Logged In!",3000,"success");   
            console.log(result);
            localStorage.setItem("user-info",JSON.stringify(result));
            history.replace('/home');

        }else{
            let res_array= Object.values(result);           // remove values from object
            let msg="";
            res_array.forEach( (i)=> msg =msg+i+"<br/>" );  //compile errors into 1 string        
            console.log(msg);
            toast(msg,5000,"danger");
        }
    }

    return (  

        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="ion-text-center"><IonTitle > Login </IonTitle></IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding ion-margin '>
                <IonGrid >
                    <IonRow>
                        <IonCol sizeXs="10" offsetXs="1" sizeSm="6"  offsetSm="3" sizeLg="5" offsetLg="4"   sizeXl="3" offsetXl="5">
                            <IonImg src="../assets/icon/icon512x512.svg" />
                        </IonCol>
                    </IonRow>

                    <IonRow >
                        <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel position="floating" color="primary">Email</IonLabel> 
                                <IonInput value={email} autofocus={true} autocomplete="email"
                                   ref={emailinput} onIonChange={(e:any) => setEmail(e.target.value)}
                                   onKeyDown={(e:any) => (e.key=="Enter")&& passinput.current.setFocus()}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow >
                        <IonCol size="10" offset="1" sizeSm="8"  offsetSm="2"  sizeLg="6" offsetLg="3"  > 
                            <IonItem>
                                <IonLabel position="floating" color="primary" >Password</IonLabel>
                                <IonInput type="password" value={password} ref={passinput}
                                    onIonChange={ (e:any) => setPassword(e.target.value)}
                                    onKeyDown={(e:any) => (e.key=="Enter")&& loginUser()} /> 
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow  className="ion-no-padding" >
                        <IonCol  size="10" offset="1" sizeSm="4" offsetSm="2" offsetLg="3"  sizeLg="3">    
                                <br/>
                                <IonButton onClick={loginUser} expand="full" shape="round" >Login
                                    <IonIcon slot="end" icon={chevronForwardCircleOutline} />
                                </IonButton> 
                                <IonText className="textsmall"> Can't Log in?</IonText>
                                <IonRouterLink href='/reset-password' className='textsmall'> Reset password .</IonRouterLink>
                        </IonCol>
                        <IonCol  size="10" offset="1" sizeSm="4" offsetSm="0" sizeLg="3"> 
                                <br/>   
                                <IonButton onClick={()=>history.push('/register')} expand="full" shape="round">
                                    Register
                                    <IonIcon slot="end" icon={addCircleOutline} />
                                </IonButton>
                        </IonCol>                      
                    </IonRow>
                </IonGrid>
            </IonContent>
            </IonPage>
    );
}

 
export default Login ;