import {IonFabButton,IonIcon,IonModal,IonLabel,IonInput,IonItem, IonHeader,
    IonToolbar,IonRow,IonCol,IonFab,IonGrid,IonSelect,IonSelectOption,IonSegment,IonAlert,
    IonSegmentButton,IonTitle,IonButton,IonContent,IonPopover} from '@ionic/react';
import {chevronForwardOutline,trashOutline,informationCircleOutline,colorPaletteOutline,arrowBackOutline} from 'ionicons/icons';
import React, {useState,useEffect} from 'react';
import { toast } from '../toast';
import { CirclePicker } from 'react-color'
import { getTankData } from '../functions';

  const SettingsModal = ({userinfo, showModal3, setShowModal3,newDevice,setNewDevice,getLast} )=> {
  const [hideTankHeight,setHideTankHeight] = useState( (newDevice.model==="custom"));
  const [showAlert, setShowAlert]=useState(false);
  const [showColorPicker, setShowColorPicker]=useState(false);
  const [popover1State, setShowPopover1] = useState({
    showPopover: false,
    event: undefined,
  });
  const [popover2State, setShowPopover2] = useState({
    showPopover: false,
    event: undefined,
  });

  const options = {
    cssClass: 'my-custom-interface'
  };


  useEffect( ()=> {   
   
    setNewDevice({...newDevice,sensorHeight: Math.round(newDevice.sensorHeight)});
    console.log(newDevice);

  },[showModal3])

  async function updateDevice(){
    console.log(newDevice);
    if(!(newDevice.sensorHeight >0)){toast("Sensor Height is required",3000,"danger");return;}
    if(!(newDevice.volume >0)){toast("Tank Volume is Required!",3000,"danger");return;}
    if(!(newDevice.fillDepth >0)){toast("Fill Depth is required!",3000,"danger");return;}
    if(newDevice.description.length == 0){toast("Please enter a name.",3000,"danger");return;}
    if(newDevice.location.length == 0){toast("Please enter a location.",3000,"danger");return;}
    if(newDevice.fillDepth > newDevice.sensorHeight){toast("Fill Depth Cannot be greater than Sensor Height",3000,"danger");return;}

    let url="https://test.castagne-technologies.icu/api/updatedevice";
    let result = await fetch(url,{
        method:"PUT",
        body:JSON.stringify(newDevice),
        headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization": "Bearer "+userinfo.token
        }
    })        

    result = await result.json();
    console.log(result);
    if (result.message) {
        if (result.message=="Device Information Updated!"){
            toast(result.message,1000,"success");        
            setShowModal3(false);                
            getLast();
        }else{
            toast(result.message,2000,"danger");    
        }
    }
  }


  async function deleteDevice(){
      console.log("deletefcn",newDevice.device_id);
      let msg={device_id:newDevice.device_id}
      let url="https://test.castagne-technologies.icu/api/deletedevice";
      let result = await fetch(url,{
          method:"DELETE",
          body:JSON.stringify(msg),
          headers:{
              "Content-Type":"application/json",
              "Accept":"application/json",
              "Authorization": "Bearer "+userinfo.token
          }
      })        

      result = await result.json();
      console.log(result);
      if (result.message) {
          if (result.message=="Device Deleted!"){
              toast(result.message,1000,"success");        
              setShowModal3(false);
              getLast();
              
          }else{
              toast(result.message,4000,"danger");    
          }
      }
  }



      return(        
    <React.Fragment>     
        <IonModal isOpen={showModal3} animated={true} cssClass="modal-class3 "  swipeToClose={true}
            backdropDismiss={true}
            onDidDismiss={ ()=>
                {
                    setShowModal3(false);
                }
            }
        >          
        <IonHeader>
            <IonToolbar color="primary">
                <IonButton slot='start' fill='clear' color='light'onClick={() =>{
                    setShowModal3(false);
                    }
                    }>
                    <IonIcon icon={arrowBackOutline}   />
                </IonButton>
                <IonTitle>Update Device Information</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className='bottomfab'>  
                <IonFabButton color="primary" onClick={updateDevice} >
                    <IonIcon icon={chevronForwardOutline} />
                </IonFabButton> 
            </IonFab>
            <IonFab vertical="top" horizontal="end" slot="fixed"> 
                <IonFabButton color="danger" onClick={()=>setShowAlert(true)} >
                    <IonIcon icon={trashOutline} />
                </IonFabButton>
            </IonFab>

            <IonGrid className="ion-padding">
                    <IonRow >
                        <IonCol size="10" offset="1" sizeSm='8' offsetSm='2' >
                            <IonItem>
                                <IonLabel position="floating" color="primary" >
                                    Name
                                </IonLabel> 
                                <IonInput value={newDevice.description} placeholder='example:  "Water Tank 1" , "South Tank" ...'
                                    onIonChange={(e) => setNewDevice( {...newDevice, description:e.target.value })}/>
                            </IonItem>
                            <IonItem>       
                                <IonLabel position="floating" color="primary" >
                                    Location
                                </IonLabel> 
                                <IonInput value={newDevice.location} placeholder='example: "Home" ,"Backyard", "Roof" ... '
                                    onIonChange={(e) => 
                                    setNewDevice( {...newDevice, location:e.target.value })} />
                            </IonItem>

                            <IonItem >
                                <IonLabel position="fixed" color="primary" >Color</IonLabel>
                                <IonButton fill="clear" onClick={()=>setShowColorPicker(true)} slot="end">
                                    <IonIcon icon={colorPaletteOutline} />
                                </IonButton>          
                            </IonItem> 
                            
                            <IonItem lines="none">
                                <IonLabel position="stacked" color="primary" >Tank Size</IonLabel>
                            </IonItem> 
                                          
                            {hideTankHeight && (   
                                <IonSelect value={newDevice.model} placeholder="Select Tank Size" 
                                    interface="action-sheet"
                                    onIonChange={e => {
                                        if(e.target.value!="custom"){ //if custom seleted do nothing
                                            console.log(e.target.value);
                                            const {sensorHeight, volume, fillDepth}=getTankData(e.target.value);
                                            setNewDevice( {...newDevice, sensorHeight:sensorHeight, volume:volume , fillDepth:fillDepth ,
                                                        model: e.target.value ,fillDepth});
                                        }
                                     }}>
                                    <IonLabel>Select: Tank Size</IonLabel>
                                    <IonSelectOption value="tuff4500">Tuff Tank-4500 Gal</IonSelectOption>
                                    <IonSelectOption value="tuff3000">Tuff Tank-3000 Gal </IonSelectOption>    
                                    <IonSelectOption value="tuff2000">Tuff Tank-2000 Gal </IonSelectOption>
                                    <IonSelectOption value="tuff1000">Tuff Tank-1000 Gal</IonSelectOption>
                                    <IonSelectOption value="tuff800">Tuff Tank-800 Gal </IonSelectOption>
                                    <IonSelectOption value="tuff600">Tuff Tank-600 Gal </IonSelectOption>
                                    <IonSelectOption value="tuff450">Tuff Tank-450 Gal </IonSelectOption>
                                    <IonSelectOption value="tuff400">Tuff Tank-400 Gal </IonSelectOption>
                                    <IonSelectOption value="tuff200">Tuff Tank-200 Gal</IonSelectOption>
                                    <IonSelectOption value="slim200">SlimLine Tank-200 Gal</IonSelectOption>
                                    <IonSelectOption value="custom">Custom</IonSelectOption>
                                </IonSelect>
                            )}

                            <IonItem>
                                <IonSegment mode="ios" value={hideTankHeight?"standard":"custom"}
                                    onIonChange={(e) => {
                                        setHideTankHeight( (e.detail.value=="standard") );
                                        if(e.detail.value=="custom"){
                                            setNewDevice( {...newDevice, model: e.detail.value });
                                        }
                                    }}>
                                        <IonSegmentButton value="standard">
                                            <IonLabel color="primary">Standard</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="custom">
                                            <IonLabel color="primary">Custom</IonLabel>
                                        </IonSegmentButton>
                                </IonSegment>
                            </IonItem>

                            {!hideTankHeight && (
                                <React.Fragment>
                                    <IonItem >
                                        <IonLabel position="stacked" color="primary">
                                            Tank Size  (Gallons)
                                        </IonLabel> 
                                        <IonInput value={newDevice.volume}  onIonChange={(e) => 
                                            setNewDevice( {...newDevice,volume:e.target.value,model:"custom" })}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel position="stacked" color="primary">Sensor Height (inch) </IonLabel> 
                                        <IonInput value={newDevice.sensorHeight}  onIonChange={(e) => 
                                            setNewDevice( {...newDevice, sensorHeight:e.target.value ,model:"custom"})} 
                                            />
                                        <IonIcon icon={informationCircleOutline} color='primary' slot='end'                                     onClick={(e: any) => {
                                            //e.persist();
                                            setShowPopover1({ showPopover: true, event: e });
                                            }}/> 
                                    </IonItem >
                                    <IonPopover
                                        cssClass="tooltip"
                                        event={popover1State.event}
                                        isOpen={popover1State.showPopover}
                                        onDidDismiss={() => setShowPopover1({ showPopover: false, event: undefined })}
                                        >
                                        <small>Measure the Vertical Distance inside the tank from the bottom of the sensor to the  tank floor </small>
                                    </IonPopover>

                                    <IonItem >
                                        <IonLabel position="stacked" color="primary">
                                            Fill Depth  (inch)                         </IonLabel> 
                                        <IonInput value={newDevice.fillDepth}  onIonChange={(e) => 
                                            setNewDevice( {...newDevice,fillDepth:e.target.value,model:"custom" })} 
                                            />
                                            <IonIcon icon={informationCircleOutline} color='primary' slot='end'                                     onClick={(e: any) => {
                                                e.persist();
                                                setShowPopover2({ showPopover: true, event: e });
                                                }}/> 
                                    </IonItem>
                                    <IonPopover
                                        cssClass="tooltip"
                                        event={popover2State.event}
                                        isOpen={popover2State.showPopover}
                                        onDidDismiss={() => setShowPopover2({ showPopover: false, event: undefined })}
                                        >
                                        <small>Measure the depth of the water inside the tank, when the tank is full </small>
                                    </IonPopover>
                                </React.Fragment>     
                            )}
                            <IonItem lines="none">
                                <IonLabel position="stacked" color="primary" >
                                        Display Unit
                                </IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonSegment mode ="ios"  
                                onIonChange={ (e) =>{
                                        setNewDevice( {
                                            ...newDevice,
                                            uom:e.target.value, 
                                            alarm: (e.target.value == "inch")? Math.round(newDevice.alarm*12) : (newDevice.alarm/12).toFixed(1)
                                         })
                                    } 
                                }
                                    value={newDevice.uom} >
                                    <IonSegmentButton value="inch">
                                        <IonLabel color="primary">Inch</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="ft">
                                        <IonLabel color="primary" >Ft</IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked" color="primary">Low Level Alarm ({newDevice.uom})</IonLabel>
                                <IonInput value={newDevice.alarm} placeholder='Notification is sent when level drops below this'
                                    onIonChange={(e) => setNewDevice( {...newDevice, alarm:e.target.value })}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <br/><br/><br/>
                    </IonRow> 
                </IonGrid>
        </IonContent>
        </IonModal> 
        

        <IonModal isOpen={showColorPicker} animated={true}  backdropDismiss={true} showBackdrop={true}
        onDidDismiss={() => setShowColorPicker(false)} cssClass="colorPickerClass"  >
            <IonGrid className="ion-padding">
                <IonRow>
                    <IonCol>
                        <CirclePicker onChangeComplete={e =>{ 
                            setNewDevice( {...newDevice, color:e.hex });
                            setShowColorPicker(false);} }
                            colors={
                                ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#77A4FF",
                                "#03a9f4", "#00bcd4", "#009688","#6370ff", "#8bc34a", "#cddc39", 
                                "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#607d8b","#000000" ]}
                        />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonModal>


        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Warning'}
          message={'<strong>This Device will be Deleted</strong>!'}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Delete',
              handler: deleteDevice
            }
          ]}
        />
    </React.Fragment>

    );
  }
  export default SettingsModal;