import * as React from 'react';
import { useEffect } from 'react';
import {useHistory ,Redirect} from 'react-router-dom'



const Protected: React.FC = (props) => {

    const history = useHistory();
    let Cmp = props.Cmp;
    

    useEffect( ()=> {                           //if not logged in rediret to login page
        if(!localStorage.getItem('user-info')){
            history.replace('/login')
        }
    },[]) //run on 1st load only


     return ( 
        <React.Fragment>
               {localStorage.getItem('user-info')&&<Cmp />} 
        </React.Fragment>
    );


};
 
export default Protected;