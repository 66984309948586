import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonGrid,IonRow,IonCol,
    IonItem,IonButton,IonInput,IonLabel,IonIcon,IonImg,IonText, IonRouterLink } from '@ionic/react';
import {chevronForwardCircleOutline,arrowBackOutline} from 'ionicons/icons';
import React, {useState,useEffect,useRef} from 'react'
import {useHistory } from 'react-router-dom'
import { toast } from '../toast'

const ResetPassword: React.FC = () => { 
const emailinput= useRef();

useEffect ( ()=>{
    setTimeout(()=>emailinput.current.setFocus(),1000);
},[])

async function SendResetEmail()
{
    let item ={email: emailinput.current.value };

    let result = await fetch("https://test.castagne-technologies.icu/forgot-password",{
        method:"POST",
        body:JSON.stringify(item),
        headers:{
            "Content-Type":"application/json",
            "Accept":"application/json"
        }
    })       
    console.log(result);
    let result1 = await result.json(); 
    (result.status==200)?toast(result1.message,3000,"success"):toast(result1.errors.email[0],3000,"danger")
    console.log(result1);
}
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="ion-text-center">
                    <IonTitle > Password Reset </IonTitle>
                    <IonButton slot='start' fill='clear' color='light' href="/login" >
                    <IonIcon icon={arrowBackOutline}   />
                    </IonButton>
                </IonToolbar>

            </IonHeader>
            <IonContent>
                <IonGrid fixed={true} > 
                    <IonRow>
                        <IonCol sizeXs="10" offsetXs="1" sizeSm="6"  offsetSm="3" sizeLg="5" offsetLg="4"   sizeXl="3" offsetXl="5">
                            <IonImg src="../assets/icon/icon512x512.svg" />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin ion padding">
                        <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonText color='medium' className="infotext">Enter the email address that you registered with .
                             An email will be sent with a link to reset your password.</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                            <IonItem>
                                <IonLabel position="floating" color="primary">Email</IonLabel> 
                                <IonInput  autocomplete="email"   
                                ref={emailinput} onIonChange={(e:any) => null}
                                onKeyDown={(e:any) => (e.key=="Enter")&& SendResetEmail()}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3">
                            <IonItem lines="none">
                                <IonButton onClick={SendResetEmail} slot='end' size='default' shape='round' >
                                    Send<IonIcon slot="end" icon={chevronForwardCircleOutline} />
                                </IonButton>
                            </IonItem> 
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>

    );

}

export default ResetPassword;