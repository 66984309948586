export function toast(message:string , duration=2000,color="dark") {
    const toast = document.createElement('ion-toast');
    toast.message = message;
    toast.duration = duration;
    toast.color=color;
  
    document.body.appendChild(toast);
    toast.present();
    return toast;
  }
  