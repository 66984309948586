import React from 'react';
import { IonMenu, IonHeader, IonToolbar, IonTitle,
  IonContent, IonList, IonItem,IonLabel, IonIcon, IonText } from '@ionic/react';
import { person,apps } from 'ionicons/icons';
import { version } from '../../package.json';

const Mainmenu: React.FC = () => {

    return(

    <IonMenu side="start"  contentId="main">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem >
            <IonLabel color='primary' ><strong> App</strong></IonLabel>
            <IonIcon icon={apps} color='primary' slot="end"/> 
          </IonItem>
          <IonItem>
            <IonText color='primary'>Version: {version}</IonText> 
          </IonItem>
          <IonItem href=""   >
            <IonText color='primary' >Refresh</IonText>  
          </IonItem>
        </IonList>

        <IonList >
          <IonItem >
            <IonLabel color='primary' ><strong>User</strong></IonLabel>
            <IonIcon icon={person} color='primary' slot="end"/>
          </IonItem>
          <IonItem href='/user'>
            <IonText color="primary"> View/Update Details</IonText>
          </IonItem>
          <IonItem href="/logout">
            <IonText color='primary'>Logout</IonText>
          </IonItem>
        </IonList> 
      </IonContent>
    </IonMenu>
    );
}
export default Mainmenu;