import * as React from 'react';
import { useEffect } from 'react';
import { useHistory} from 'react-router-dom';
import { IonSpinner} from '@ionic/react';


const Logout: React.FC = () => {

    const history = useHistory();
   
    useEffect( ()=> {                           //if not logged in rediret to login page
        if(localStorage.getItem('user-info')){
            localStorage.removeItem('user-info') ;
            window.location.reload(true);
            history.push('/login');
        }
    },[]) //run on 1st load only


     return ( 
        <IonSpinner name="lines-small" />

    );


};
 
export default Logout;