import { IonContent, IonPage,IonGrid,IonRow,IonCol,IonFab,IonFabButton,
          IonIcon,IonCard,IonItem,IonButton} from '@ionic/react';
import React, {useState,useEffect,useRef} from 'react'
import LevelChart from '../components/LevelChart';
import AddDevModal from '../components/AddDevModal';
import AddDevModalpg2 from '../components/AddDevModalpg2';
import NotificationModal from '../components/NotificationModal';
import Header from '../components/Header';
import SettingsModal from '../components/SettingsModal';
import firebase from '../firebase';
import {addOutline, settingsSharp,podium} from 'ionicons/icons';
import {useHistory} from 'react-router-dom'
import { toast } from '../toast';


const Home: React.FC = () => {
  const history=useHistory();
  const [offlineMode, setOfflineMode]= useState(false);
  const timesToRetry =useRef(3);
  const retryCount   = useRef(1);
  const API_input = useRef({current:{ focus:"1"} });
  const activeToast= useRef();
  const [userinfo , setUserinfo] = useState(JSON.parse(localStorage.getItem('user-info')));
  const [showModal, setShowModal] = useState(false);//Add device #1 API modal
  const [showModal2, setShowModal2] = useState(false);//Add device #2 modal
  const [showModal3, setShowModal3] = useState(false);//settings modal
  const [showModal4, setShowModal4] = useState(false); //notification modal
  const [newDeviceFlag ,setNewDeviceFlag] = useState(false);
  const [firstRun, setFirstRun] =useState(true);
  const [newDevice, setNewDevice]= useState( 
    { 
      device_id:"",
      user_id:userinfo.user.id, 
      api_key:"",
      type:"Level",
      description:"",
      location:"",
      maxval:"100",
      minval:"0",
      type:"",
      uom:"",
      alarm:"0",
      model:"tuff1000",
      volume:1000,
      fillDepth:78,
      sensorHeight:91
  });

  async function getLastReading()  //future note  userinfo may change elsewhere while this is runing
  {
    try{
        if(showModal || showModal2 || showModal3) return;
        
        let msg= {user_id:userinfo.user.id , take:1};
        let result = await fetch("https://test.castagne-technologies.icu/api/getlast",{
              method:"POST",
              body:JSON.stringify(msg),
              headers:{
                  "Content-Type":"application/json",
                  "Accept":"application/json",
                  "Authorization": "Bearer "+userinfo.token
              }
          }).catch((err)=>{
             activeToast.current?.dismiss();
             activeToast.current=toast("No Internet Connection",8000,"danger");
          });      
       
        result = await result.json();
      if(!(result.message.hasOwnProperty('devices') && result.message.hasOwnProperty('user'))) return ;
      setUserinfo( prev => {
        let p= JSON.parse( JSON.stringify(prev));
        p.devices= result.message.devices;
        p.user=result.message.user;
        localStorage.setItem("user-info",JSON.stringify(p));
        return p  ;
      } );            //update last value
    }catch(err){
        console.log(err);
    }
  }

  async function storeFCMToken(fcmtoken)  //Send Firebase FCM token to backend Database with userid for storing
  {
    try{
      if(showModal || showModal2 || showModal3) return;
      let msg= {user_id:userinfo.user.id , fcm_token: fcmtoken};
      let result = await fetch("https://test.castagne-technologies.icu/api/storefcmtoken",{
            method:"POST",
            body:JSON.stringify(msg),
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json",
                "Authorization": "Bearer "+userinfo.token
            }
        })      
        result = await result.json();
        console.log(result.message); 
    }catch(err){
      if ( err.message.toLowerCase().includes("fetch"))
      {
        activeToast.current?.dismiss();
        activeToast.current = toast("No Internet Connection",8000,"danger");
      }
    }

      
  }             
    

  useEffect( ()=> {                           //if already logged in redirect to home page
    if(localStorage.getItem('user-info') && firstRun ){
      console.log('firstrun');
        setFirstRun(false);
        setUserinfo( JSON.parse(localStorage.getItem('user-info')))
        getLastReading();
        console.log(newDevice);
        try{
            const msg=firebase.messaging();
      
            msg.requestPermission().then(()=>{
              return msg.getToken();
            }).then((data)=>{
              console.warn("token",data);
              storeFCMToken(data) ;      //store Firebase FCM token in database with userid
            })
        }catch(error){
            console.log("notification setup failed:\n");
            console.log(error);
        }
        const timer1= setInterval(getLastReading, 5000);  
        
    }
    return () => {
      if (typeof timer1 !="undefined")clearInterval(timer1);
    }
  
  },[userinfo])

  useEffect( ()=> { 
    history.block();
  },[])


  return (
    <React.Fragment>
      <IonPage>        
        <Header setShowModal4={setShowModal4} />
        <IonContent fullscreen >                                                 {/*Modal*/}
          <IonFab vertical="bottom" horizontal="start" slot="fixed">  
            <IonFabButton onClick={()=>{
                  setShowModal(true); 
                  setTimeout( ()=> API_input?.current?.setFocus(), 500); 
               } }>
              <IonIcon icon={addOutline} />
            </IonFabButton>
          </IonFab>

          <AddDevModal showModal={showModal} setShowModal={setShowModal} setShowModal2={setShowModal2} 
            newDevice={newDevice} setNewDevice={setNewDevice} userinfo={userinfo} 
            newDeviceFlag={newDeviceFlag} setNewDeviceFlag={setNewDeviceFlag} API_input={API_input}/>   

          <AddDevModalpg2 showModal2={showModal2} setShowModal2={setShowModal2} newDevice={newDevice}
            setNewDevice={setNewDevice} userinfo={userinfo}  newDeviceFlag={newDeviceFlag} 
            setNewDeviceFlag={setNewDeviceFlag} getLastReading={getLastReading}
            />     
          <SettingsModal showModal3={showModal3} setShowModal3={setShowModal3} newDevice={newDevice} 
                         setNewDevice={setNewDevice} userinfo={userinfo} getLast={getLastReading}/> 
          <NotificationModal userinfo={userinfo} showModal4={showModal4} setShowModal4={setShowModal4}  
                         userinfo={userinfo} getLast={getLastReading}/>   
                                                                                {/*Main Page*/}
          <IonGrid>
              {/*console.log(userinfo)*/}
              { Array.isArray(userinfo.devices) && (   //only render if user logged in
                userinfo.devices.map((device , index) => (     
                <IonRow className="ion-text-center" key={index}> 
                    <IonCol offset="0" size ="12" offset-md="1" offset-lg="2" size-lg="8" size-md="10"  className="ion-text-center">
                      <IonCard>
                        <IonItem lines="none">
                          <IonButton  slot="start" color="dark" fill="clear" 
                          href={'/chart?token='+encodeURI(userinfo.token)+'&id='+encodeURI(device.id)}>
                            <IonIcon icon={podium}  />
                          </IonButton>
                          <IonButton  slot="end" color="dark" fill="clear"  onClick={()=> {
                            setNewDevice({...device,device_id:device.id,user_id:userinfo.user.id})
                            setShowModal3(true) }} >
                            <IonIcon icon={settingsSharp}  />
                          </IonButton>
                        </IonItem>
                        <LevelChart value={device.lastreading} desc= {device.description} 
                        location={device.location} uom={device.uom}  color={device.color} 
                        updated={device.updated_at} timeout={device.timeout} fillDepth={device.fillDepth}
                        volume={device.volume} sensorHeight={device.sensorHeight} />
                      </IonCard>
                  </IonCol>
                </IonRow>
              )))}
          </IonGrid>
        </IonContent>
      </IonPage>
  </React.Fragment>
  );
};

export default Home;
