export function getTankData(model:string) {
   
  const TankLookupTable= 
  {   //heingt in  inches , volume in gallons , diameter in inches
     "tuff4500": { volume:4500 ,fillDepth:155, sensorHeight:179},
     "tuff3000": { volume:3000 ,fillDepth:124, sensorHeight:139},
     "tuff2000": { volume:2000 ,fillDepth:103, sensorHeight:120},
     "tuff1000": { volume:1000 ,fillDepth:79 , sensorHeight:91 },//verified
     "tuff800" : { volume:800  ,fillDepth:80 , sensorHeight:87},//verified
     "tuff600" : { volume:600  ,fillDepth:62 , sensorHeight:72},//verified
     "tuff450" : { volume:450  ,fillDepth:64 , sensorHeight:74},
     "tuff400" : { volume:400  ,fillDepth:53 , sensorHeight:63}, //verfied
     "tuff200" : { volume:200  ,fillDepth:44 , sensorHeight:47},
     "slim200" : { volume:200  ,fillDepth:66 , sensorHeight:76},//verified
  }
  return TankLookupTable[model];
}
  