import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonGrid,IonRow,IonCol
  ,IonItem,IonButton,IonInput,IonLabel,IonIcon,IonAvatar } from '@ionic/react';
import {chevronForwardCircleOutline, arrowBackOutline} from 'ionicons/icons';
import {useState,useEffect,useRef}from 'react';
import { toast } from '../toast';
import {useHistory}from 'react-router-dom'




const User= () => {

  const [userinfo , setUserinfo] = useState(JSON.parse(localStorage.getItem('user-info')));
  const [id , setId]=useState('');
  const [firstName,setFirstname] = useState('');
  const [lastName,setLastname] =   useState('');
  const [email,setEmail] =         useState('');
  const [phone,setPhone] =         useState('');
  const [password,setPassword] =   useState('');
  const [cpassword,setCpassword] =  useState('');
  const passinput=useRef();
  const history = useHistory();
  


  useEffect( ()=> {            //Populate informationf from page
      setEmail(userinfo.user.email);
      setFirstname(userinfo.user.first_name);
      setLastname(userinfo.user.last_name);
      setPhone(userinfo.user.phone);
      setId(userinfo.user.id);
  },[]) //run on 1st load only


  async function UpdateUser(){
    if(password != cpassword)
    { 
        toast("Passwords must match!",2000,"danger");
        setPassword('');
        setCpassword('');
        return;
    }

    let item ={ user_id:id , first_name:firstName,last_name:lastName,email:email, phone:phone};
    item=(password)?({...item,password:password}):item;
    let response= await fetch("https://test.castagne-technologies.icu/api/updateuser",{
        method:"PUT",
        body:JSON.stringify(item),
        headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization": "Bearer "+userinfo.token
        }
    });
    
    if (response.status >= 200 && response.status <= 299) {
        const jsonResponse = await response.json();
        console.log(jsonResponse);
        toast("User Info Updated!",2000,"success");
        history.replace('/home');
    } else {// Handle errorss              
        console.log(response.status, response.statusText);
        toast("Error"+response.statusText,5000,"danger");    }
  }

  return (  
      <IonPage>
          <IonHeader>
              <IonToolbar color="primary" className="ion-text-center">
                <IonButton  slot="start" color="light"  fill="clear" href="/home">
                      <IonIcon icon={arrowBackOutline} />
                </IonButton>
                <IonTitle > User Information </IonTitle>
                </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding ion-margin '>
              <IonGrid >
                <IonRow >
                    <IonCol size='2' offset='6' sizeLg='2' offsetLg='6'>
                    <IonAvatar >
                        <img src="./assets/icon/avatar.png" />
                    </IonAvatar> 
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol  size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                        <IonItem>
                                <IonLabel position="floating"  color="primary" className="ion-justify-content-center">First Name</IonLabel>
                                <IonInput value={firstName} 
                                    onIonChange={(e) => setFirstname(e.target.value)}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol  size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                        <IonItem>
                            <IonLabel  position="floating" color="primary">Last Name</IonLabel>
                            <IonInput value={lastName}  
                                onIonChange={(e) => setLastname(e.target.value)}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                        <IonItem>
                            <IonLabel position="floating" color="primary">Email</IonLabel> 
                            <IonInput value={email} 
                            onIonChange={(e) => setEmail(e.target.value)}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                        <IonItem>
                            <IonLabel position="floating" color="primary">Phone</IonLabel> 
                            <IonInput value={phone} 
                            onIonChange={(e) => setPhone(e.target.value)}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol  size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                        <IonItem>
                            <IonLabel position="floating" color="primary" >Password</IonLabel>
                            <IonInput type="password" value={password} ref={passinput}
                            onIonChange={(e) => setPassword(e.target.value)}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol   size="10" offset="1" sizeSm="8" offsetSm="2" sizeLg="6" offsetLg="3" > 
                        <IonItem>
                            <IonLabel position="floating" color="primary" >Confirm Password</IonLabel>
                            <IonInput type="password" value={cpassword}
                            onIonChange={(e) => setCpassword(e.target.value) } onKeyDown={(e) => (e.key=="Enter")&& UpdateUser() } />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow  >
                    <IonCol size='8' offset="2"  className='ion-padding'>    
                        <IonButton onClick={UpdateUser} expand="full" shape='round'>Update User Info
                            <IonIcon slot="end" icon={chevronForwardCircleOutline} />
                        </IonButton>
                    </IonCol>
                </IonRow>

              </IonGrid>
          </IonContent>
          </IonPage>
  );
}

export default User ;